import { AuthManagerService } from 'services/auth/authManager.service';
import { useUserStore } from 'store/user.store';
import BasesService from 'services/basesManager.service';

export async function useSetServerUserInfo(): Promise<void> {
  const basesManager = inject<BasesService>(BasesService.getServiceName());

  if (process.server) {
    try {
      const userStore = useUserStore();
      const userInfo = await AuthManagerService.getServerUserInfo();
      if (!userInfo) {
        return;
      }

      userStore.setUserInfo(userInfo);
      if (basesManager && userInfo.baseToSelect) {
        basesManager.currentUserBase = userInfo.baseToSelect;
      }
    } catch (error) {
      console.error('Error - useSetServerUserInfo: ', error);
    }
  }
}
